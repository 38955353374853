<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-1">
          <span class="overview-title">Plans de passation de marché</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ plans.length }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-xl-12 p-col-12">
        <div class="card no-gutter orders">
          <!--
                  <div class="p-grid">
                      <div class="p-col-12">
                          <div id="order-tabs-container" class="p-grid order-tabs"></div>
                      </div>
                  </div>-->
          <div class="card">
            <DataTable
              :value="plans"
              v-model:expandedRows="projetsMarches"
              data-key="id"
              responsiveLayout="scroll"
              @row-expand="onRowExpand"
              @row-collapse="onRowCollapse"
              :paginator="true"
              :rows="10"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="{totalRecords} plans de passation de marché"
            >
              <template #header>
                <div
                  class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center"
                >
                  <h5 class="p-mb-2 p-m-md-0">Gestion des plans de passation de marché</h5>

                  <span v-bind:style="{ width: '23%' }" class="">
                    <InputText
                      v-bind:style="{ width: '88%' }"
                      v-model="entrepriseName"
                      placeholder="Rechercher une entreprise"
                    />
                    <Button
                      type="button"
                      @click="searching"
                      icon="pi pi-search"
                      class="p-button-success p-d-none p-d-md-inline-flex"
                    />
                  </span>
                </div>
                <br />
                <div style="float: right">
                  <h6 v-if="errorOnSearch" style="color: red">Aucune entreprise n'a été trouvée.</h6>
                  <h6 v-if="errorOnSearchSize" style="color: red">Le champ de recherche est vide.</h6>
                </div>
                <!--<MultiSelect id="state" v-model="selectedStatusParams" :options="dropdownValues" optionLabel="name"></MultiSelect>-->
                <Dropdown id="state" v-model="dropdownValue" :options="dropdownValues" optionLabel="name"></Dropdown>
                <div>
                  <hr />
                  <a style="margin: 5px" href="#" @click="exportPlans($event)"
                    ><i class="pi pi-download"></i> Exporter les plans de passations</a
                  >
                  <a style="margin: 5px" href="#" @click="exportProjetsMarches($event)"
                    ><i class="pi pi-download"></i> Exporter les projets de marchés</a
                  >
                </div>
                <!--<h6>filtre : </h6>
                                <input type='checkbox' value='0' v-model='selectedStatusParams' /> Créé
                                <input type='checkbox' value='1' v-model='selectedStatusParams' /> Transmis
                                <input type='checkbox' value='2' v-model='selectedStatusParams' /> Valide
                                <input type='checkbox' value='3' v-model='selectedStatusParams' /> Non approuvé-->
              </template>
              <Column :expander="true" />
              <Column field="entrepriseName" header="Nom de l'entreprise" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Name</span>
                  <a style="cursor: pointer; font-weight: bold" @click="onOpenDetails(slotProps.data.id)">{{
                    slotProps.data.entrepriseName
                  }}</a>
                </template>
              </Column>
              <Column field="annee" header="Année" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Année</span>
                  {{ slotProps.data.annee }}
                </template>
              </Column>
              <Column field="revision" header="Nombre de révision" :sortable="true" bodyStyle="text-indent:5em">
                <template #body="slotProps">
                  <span class="p-column-title"></span>
                  {{ slotProps.data.revision === -1 ? 0 : slotProps.data.revision }}
                </template>
              </Column>
              <Column field="statut" header="Statut" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">STATUT</span>
                  <span v-if="slotProps.data.statut == 'VALIDE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                  <span v-if="slotProps.data.statut == 'TRANSMIS'" :class="'product-badge status-lowstock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                  <span v-if="slotProps.data.statut == 'NON_APPROUVE'" :class="'product-badge status-outofstock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                  <span v-if="slotProps.data.statut == 'CREE'" :class="'product-badge status-init'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Actions</span>
                  <Button
                    icon="pi pi-thumbs-up"
                    v-show="
                      slotProps.data.statut !== 'CREE' &&
                      slotProps.data.statut !== 'VALIDE' &&
                      slotProps.data.statut !== 'NON_APPROUVE'
                    "
                    v-tooltip.top="'Valider'"
                    class="p-button-outlined p-button-rounded p-button-success p-mr-2"
                    @click="valid(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-thumbs-down"
                    v-tooltip.top="'Retourner pour correction'"
                    class="p-button-outlined p-button-rounded p-button-warning p-mr-2"
                    v-if="slotProps.data.statut == 'TRANSMIS'"
                    @click="handleCorrections(slotProps.data)"
                  />
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="orders-subtable">
                  <h5>Les projets de marché rattachés</h5>
                  <DataTable
                    :value="slotProps.data.projetMarches"
                    responsiveLayout="scroll"
                    :paginator="true"
                    :rows="10"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="{totalRecords} projets de marché"
                  >
                    <Column field="intitule" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
                      <template #body="slotProps">
                        <span class="p-column-title">Intitulé</span>
                        {{ slotProps.data.intitule }}
                      </template>
                    </Column>

                    <Column
                      field="naturePrestation.libelle"
                      header="Nature prestation"
                      :sortable="true"
                      bodyStyle="text-indent:1em"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title"></span>
                        {{ slotProps.data.naturePrestation.libelle }}
                      </template>
                    </Column>
                    <Column field="description" header="Description" :sortable="true" bodyStyle="text-indent:1em">
                      <template #body="slotProps">
                        <span class="p-column-title"></span>
                        {{ slotProps.data.description }}
                      </template>
                    </Column>
                    <Column field="projetMarcheRegime" header="Régime" :sortable="true" bodyStyle="text-indent:1em">
                      <template #body="slotProps" bodyStyle="text-indent:1em">
                        <span class="p-column-title"></span>
                        {{ enumMap[slotProps.data.projetMarcheRegime] }}
                      </template>
                    </Column>
                    <Column
                      field="datePrevuLancement"
                      header="Date prévue de publication"
                      :sortable="true"
                      bodyStyle="text-indent:1em"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">Date prévue de lancement</span>
                        {{ getFormattedDate(slotProps.data.datePrevuLancement) }}
                      </template>
                    </Column>
                    <Column
                      field="datePrevuLAttribution"
                      header="Date prévue d'attribution"
                      :sortable="true"
                      bodyStyle="text-indent:1em"
                    >
                      <template #body="slotProps" :sortable="false" bodyStyle="text-align: center">
                        <span class="p-column-title">Date prévue attribution</span>
                        {{ getFormattedDate(slotProps.data.datePrevuLAttribution) }}
                      </template>
                    </Column>
                    <Column field="action" header="Actions">
                      <template #body="slotProps">
                        <span class="p-column-title">Actions</span>
                        <Button
                          icon="pi pi-eye"
                          class="p-button-outlined p-button-rounded p-button-submit"
                          @click="openDetails(slotProps.data)"
                          v-tooltip.top="'Voir les détails'"
                        />
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <Sidebar
      v-model:visible="dialogs.detailPlan.opened"
      position="right"
      class="p-sidebar-md"
      @close="onUnsetSelectedPlanId"
    >
      <h4>Plan de passation de marché</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          Année : <b style="float: right">{{ selectedPlan?.annee }}</b>
        </li>
        <li class="detail-list-item">
          Nombre de révision :
          <b style="float: right">{{ selectedPlan?.revision === -1 ? 0 : selectedPlan?.revision }}</b>
        </li>
        <li class="detail-list-item">
          Statut : <b style="float: right">{{ enumMap[selectedPlan?.statut] }}</b>
        </li>
        <li class="detail-list-item">
          Entreprise : <b style="float: right">{{ selectedPlan?.entrepriseName }}</b>
        </li>
        <li class="detail-list-item">
          Point focal : <b style="float: right">{{ selectedPlan?.focalPoint.name }}</b>
        </li>
        <li class="detail-list-item">
          Email : <b style="float: right">{{ selectedPlan?.focalPoint.email }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 1 : <b style="float: right">{{ selectedPlan?.focalPoint.mobile }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 2 : <b style="float: right">{{ selectedPlan?.focalPoint.phone || 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Site web : <b style="float: right">{{ selectedPlan?.focalPoint.entreprise.website || 'N/A' }}</b>
        </li>
        <li v-if="selectedPlan?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right; color: red">{{ selectedPlan?.motifRenvoi }}</b>
        </li>
        <li v-if="!selectedPlan?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right">{{ 'N/A' }}</b>
        </li>
      </ul>
      <br />
      <h6>Projet(s) de marché</h6>
      <DataTable :value="selectedPlan.projetMarches" responsiveLayout="scroll">
        <Column field="customer" header="Intitulé" :sortable="false" bodyStyle="text-indent:1em">
          <template #body="slotProps">
            <span class="p-column-title">Intitulé</span>
            {{ slotProps.data.intitule }}
          </template>
        </Column>

        <Column field="date" header="Date prévue de lancement" :sortable="false" bodyStyle="text-indent:1em">
          <template #body="slotProps">
            <span class="p-column-title">Date prévue de lancement</span>
            {{ getFormattedDate(slotProps.data.datePrevuLancement) }}
          </template>
        </Column>

        <Column field="amount" header="Date prévue d'attribution" :sortable="false" bodyStyle="text-indent:1em">
          <template #body="slotProps" :sortable="false">
            <span class="p-column-title">Date prévue attribution</span>
            {{ getFormattedDate(slotProps.data.datePrevuLAttribution) }}
          </template>
        </Column>

        <Column field="action" header="Actions" bodyStyle="text-indent:1em">
          <template #body="slotProps">
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'Voir les détails'"
              class="p-button-outlined p-button-rounded p-button-submit"
              @click="openDetails(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
      <br />
      <Button
        label="Retourner pour correction"
        icon="pi pi-thumbs-down"
        class="p-button-outlined p-button-rounded p-button-warning p-mr-2"
        v-if="selectedPlan.statut == 'TRANSMIS'"
        @click="handleCorrections(selectedPlan)"
      />
      <Button
        label="Valider"
        icon="pi pi-thumbs-up"
        v-show="
          selectedPlan.statut !== 'CREE' && selectedPlan.statut !== 'VALIDE' && selectedPlan.statut !== 'NON_APPROUVE'
        "
        class="p-button-outlined p-button-rounded p-button-success p-mr-2"
        @click="valid(selectedPlan)"
      />
    </Sidebar>

    <Sidebar
      v-model:visible="dialogs.detailProjetMarche.opened"
      position="right"
      class="p-sidebar-md"
      @close="onUnsetSelectedProjetMarcheId"
    >
      <h4>Détails du projet de marché</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          Intitulé : <b style="float: right">{{ selectedProjetMarche?.intitule }}</b>
        </li>
        <li class="detail-list-item">
          Type de régime :
          <b style="float: right">{{ selectedProjetMarche?.regimes?.map((name) => enumMap[name]).join(', ') }}</b>
        </li>
        <li class="detail-list-item">
          Référence : <b style="float: right">{{ selectedProjetMarche?.reference }}</b>
        </li>
        <li class="detail-list-item">
          Nature de la prestation : <b style="float: right">{{ selectedProjetMarche?.naturePrestation.libelle }}</b>
        </li>
        <li class="detail-list-item">
          Domaine(s) métier(s) :
          <b>
            <div v-for="d in selectedProjetMarche?.domaineMetiers" :key="d.id">- {{ d.libelle }}</div>
          </b>
        </li>
        <li class="detail-list-item">
          Description : <b style="float: right">{{ selectedProjetMarche?.description }}</b>
        </li>
        <li class="detail-list-item">
          Date prévue de lancement :
          <b style="float: right">{{ getFormattedDate(selectedProjetMarche?.datePrevuLancement) }}</b>
        </li>
        <li class="detail-list-item">
          Date prévue d'attribution :
          <b style="float: right">{{ getFormattedDate(selectedProjetMarche?.datePrevuLAttribution) }}</b>
        </li>
      </ul>
    </Sidebar>

    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '600px' }"
      header="Rejeter le plan de passation de marché"
      :modal="true"
      class="p-fluid"
    >
      <label for="description">Objet(s) du rejet</label>
      <p></p>
      <Textarea id="price" v-model="motif_rejet" required="true" rows="10" cols="25" />

      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button
          label="Rejeter"
          icon="pi pi-delete"
          class="p-button p-component p-button-outlined p-button-success"
          @click="rejected(ppm)"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="correctionDialog"
      :style="{ width: '600px' }"
      header="Correction du plan de passation de marché"
      :modal="true"
      class="p-fluid"
    >
      <label for="description">Objet(s) de la correction</label>
      <p></p>
      <Textarea
        :class="{ 'p-invalid': !motif_renvoi }"
        id="price"
        v-model="motif_renvoi"
        required="true"
        rows="10"
        cols="25"
      />
      <small v-if="!motif_renvoi" class="p-error"> Le motif du rejet est obligatoire. </small>
      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button
          label="Renvoyer"
          icon="pi pi-delete"
          class="p-button p-component p-button-outlined p-button-success"
          @click="correction(ppm)"
          :disabled="!motif_renvoi"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="validDialog"
      :style="{ width: '600px' }"
      header="Êtes-vous sûr de vouloir valider le plan de passation de marché ?"
      :modal="true"
      class="p-fluid"
    >
      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog" />
        <Button label="OUI" class="p-button p-component p-button-outlined p-button-success" @click="update(ppm)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapActions, mapGetters } from 'vuex';
import EnumFormatMixin from '@/mixins/enum-format';
import DateFormatMixin from '@/mixins/date-format';
import moment from 'moment';
import http from '../services/http.service';

export default {
  mixins: [EnumFormatMixin, DateFormatMixin],
  data() {
    return {
      dialogs: {
        detailPlan: { opened: false },
        detailProjetMarche: { opened: false },
      },
      listppm: [],
      ppm: null,
      user: null,
      config: null,
      token: '',
      id: '',
      id_pm: null,
      entrepriseId: '',
      entrepriseName: '',
      intitule: '',
      objet: '',
      reference: '',
      realisations: '',
      date_fin: '',
      date_debut: '',
      isEditing: false,
      type_marche: null,
      domaines: null,
      type_marches: ['EXCLUSIF', 'MIXTE', 'LIBRE'],
      motif_renvoi: null,
      mode_passation: null,
      mode_passations: ['DRP', 'AO', 'AMI'],
      nature_prestation: null,
      list_nature_prestation: [],
      type_regime: null,
      type_regimes: ['EXCLUSIF', 'MIXTE', 'NON_EXCLUSIF'],
      totalPPM: 0,
      isDO: false,
      editDialog: false,
      validDialog: false,
      deleteDialog: false,
      correctionDialog: false,
      isError: false,
      sended: false,
      motif_rejet: '',
      projetsMarches: [],
      selectedStatusParams: [],
      dropdownValue: { name: 'Transmis', id: 1 },
      dropdownValues: [
        { name: 'Créé(s)', id: 0 },
        { name: 'Renvoyé(s)', id: 3 },
        { name: 'Transmis', id: 1 },
        { name: 'Validé(s)', id: 2 },
        { name: 'Tous', id: 4 },
      ],
      errorOnSearch: false,
      errorOnSearchSize: false,
      visibleLeft: false,
      visibleSearch: false,
      detail: null,
      details: null,
      selectedProjetMarche: null,
    };
  },
  mounted() {
    this.token = localStorage.getItem('token');
    this.config = { headers: { Authorization: `Bearer ${this.token}` } };

    this.fetchAllPlans({ queryParams: { statuts: 1 } });
  },
  computed: {
    ...mapGetters({
      plans: 'ppm/getAllEntities',
      selectedPlan: 'ppm/getEntity',
    }),
  },
  methods: {
    ...mapActions({
      fetchAllPlans: 'ppm/fetchAll',
      updatePlan: 'ppm/update',
    }),
    openDetails(x) {
      this.selectedProjetMarche = x;
      this.dialogs.detailProjetMarche.opened = true;
    },
    exportPlans(event) {
      event.preventDefault();
      const data = this.plans.map((plan) => ({
        ID: plan.id,
        ANNEE: plan.annee,
        STATUT: plan.statut,
        REVISION: plan.revision < 0 ? 0 : plan.revision,
      }));
      const fileName = `plans-passation-marche-${moment().format('DDMMYYYYHHmmss')}.xlsx`;
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'plans_passation_marche');
      XLSX.writeFile(wb, fileName);
    },
    exportProjetsMarches(event) {
      event.preventDefault();
      event.preventDefault();
      const fileName = `projets-marches-${moment().format('DDMMYYYYHHmmss')}.xlsx`;
      let projetsMarches = this.plans.reduce(
        (h, plan) => [
          ...h,
          ...plan.projetMarches.map((project) => ({
            ID: project.id,
            REFERENCE: project.reference,
            INTITULE: project.intitule,
            DESCRIPTION: project.description,
            DATE_PREVU_LANCEMENT: project.datePrevuLancement,
            DATE_PREVU_ATTRIBUTION: project.datePrevuLAttribution,
            REGIMES: project.regimes.join('|'),
            NATURE_PRESTATION: project.naturePrestation.libelle,
            DOMAINES_METIERS: project.domaineMetiers.map((d) => d.libelle).join(' | '),
            ANNEE: project.planPassationMarcheAnnee,
            NB_AMI: project.countManifestationsInterets,
            NB_AO: project.countAppelsOffres,
            PPM_ID: project.planPassationMarcheId,
          })),
        ],
        []
      );
      console.log(projetsMarches);
      const ws = XLSX.utils.json_to_sheet(projetsMarches);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Projets Marches');
      XLSX.writeFile(wb, fileName);
    },
    async filterPPM(etat) {
      if (etat === 4) {
        this.fetchAllPlans({ queryParams: { limit: 5000, etat: [0, 1, 2, 3] } });
      } else {
        this.fetchAllPlans({ queryParams: { statuts: etat } });
      }
    },
    async searching() {
      this.errorOnSearch = false;
      this.errorOnSearchSize = false;
      if (this.entrepriseName.length >= 1) {
        if (this.dropdownValue.id === 4) {
          this.fetchAllPlans({
            queryParams: { limit: 1000, entrepriseName: this.entrepriseName, etat: [0, 1, 2, 3] },
          });
        } else {
          this.fetchAllPlans({
            queryParams: { entrepriseName: this.entrepriseName, statuts: this.dropdownValue.id },
          });
        }
      } else {
        this.errorOnSearchSize = true;
        setTimeout(() => (this.errorOnSearchSize = false), 3000);
        this.entrepriseName = '';
        this.dropdownValue = { name: 'Tous', id: 0 };
        this.fetchCompany(0);
      }
    },
    async update(ppm) {
      this.updatePlan({
        data: {
          id: ppm.id,
          annee: ppm.annee,
          statut: 'VALIDE',
          revision: ppm.statut === 'CREE' ? 0 : ppm.revision + 1,
          entrepriseId: this.ppm.entrepriseId,
          motifRenvoi: '',
          motifRejet: '',
        },
        payload: {
          isAdmin: true,
          queryParams: { flag: 'SET_NEW_STATUS' },
        },
      });
      this.hideDialog();
      /*console.log('update =>' + ppm.id);
      const token = localStorage.getItem('token');
      
      try {
        await http.put(
          '/plans-passation-marches/' + ppm.id + '/',
          {
            annee: ppm.annee,
            statut: 'VALIDE',
            revision: ppm.statut === 'CREE' ? 0 : ppm.revision + 1,
            entrepriseId: this.ppm.entrepriseId,
            motifRenvoi: '',
            motifRejet: '',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.validDialog = false;
        this.fetchAllPlans;
      } catch (e) {
        console.log('error when updating => ' + e);
      }*/
    },
    async rejected(ppm) {
      const token = localStorage.getItem('token');
      try {
        console.log('ENTREPRISE => ' + ppm.entrepriseId);
        await http.put(
          '/plans-passation-marches/' + ppm.id + '/',
          {
            annee: ppm.annee,
            statut: 'NON_APPROUVE',
            entrepriseId: ppm.entrepriseId,
            motifRejet: this.motif_rejet,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.deleteDialog = false;
        this.fetchAllPlans();
      } catch (e) {
        console.log('error when updating => ' + e);
      }
    },
    async correction(ppm) {
      const token = localStorage.getItem('token');
      try {
        console.log('ENTREPRISE => ' + ppm.entrepriseId);
        await http.put(
          '/plans-passation-marches/' + ppm.id + '/?flag=SET_NEW_STATUS',
          {
            annee: ppm.annee,
            statut: 'NON_APPROUVE',
            revision: ppm.revision,
            entrepriseId: ppm.entrepriseId,
            motifRenvoi: this.motif_renvoi,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.correctionDialog = false;
        this.fetchAllPlans();
      } catch (e) {
        console.log('error when updating => ' + e);
      }
    },
    onOpenDetails(ppmId) {
      this.$store.commit('ppm/setSelectedEntityId', ppmId);
      this.dialogs.detailPlan.opened = true;
    },
    onUnsetSelectedPlanId() {
      this.detail = null;
      this.$store.commit('ppm/setSelectedEntityId', null);
    },
    onUnsetSelectedprojet() {
      this.details = null;
    },
    valid(pm) {
      this.dialogs.detailPlan.opened = false;
      this.ppm = pm;
      this.validDialog = true;
    },
    remove(pm) {
      this.id_pm = pm.id;
      this.motif_rejet = '';
      this.ppm = pm;
      this.deleteDialog = true;
    },
    handleCorrections(pm) {
      this.dialogs.detailPlan.opened = false;
      this.id_pm = pm.id;
      this.ppm = pm;
      this.correctionDialog = true;
    },
    showDialog() {
      this.intitule = '';
      this.objet = '';
      this.type_regime = null;
      this.reference = '';
      this.mode_passation = null;
      this.realisations = null;
      this.editDialog = true;
      this.isEditing = false;
      this.isError = false;
    },
    edit(ppm) {
      this.id_pm = ppm.id;
      this.intitule = ppm.intitule;
      this.type_regime = ppm.projetMarcheRegime;
      this.reference = ppm.reference;
      this.nature_prestation = ppm.naturePrestation;
      this.realisations = ppm.description;
      this.date_debut = ppm.datePrevuLancement;
      this.date_fin = ppm.datePrevuLAttribution;
      this.isEditing = true;
      this.isError = false;
      this.editDialog = true;
    },
    hideDialog() {
      this.editDialog = false;
      this.deleteDialog = false;
      this.correctionDialog = false;
      this.validDialog = false;
    },
  },
  watch: {
    /*selectedStatusParams: function (value) {
      console.log({ value });
      if (Object.values(value).length > 0) this.fetchAllPlans({ queryParams: { statuts: value.map((v) => v.id).join(',') } });
    },*/
    dropdownValue: {
      handler: function (dropdownValue) {
        //console.log(dropdownValue.id);
        this.filterPPM(dropdownValue.id);
      },
    },
  },
};
</script>

<style lang="scss">
.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-init {
    background: #deddf5;
    color: #101b61;
  }
}
</style>
